import { StateCreator } from 'zustand'
import {
  IContentRecommendation,
  IContentRecommendationStateDefault,
} from './interface'
import get from 'lodash/get'

const resetters: (() => void)[] = []
const INITIAL_DATA: IContentRecommendationStateDefault = {
  contentRecommendation: {
    list: [],
    loadMore: false,
    currentPage: 1,
  },
}

export const createContentRecommendationSlice: StateCreator<
  IContentRecommendation
> = (set) => {
  resetters.push(() => set(INITIAL_DATA))

  return {
    ...INITIAL_DATA,
    setContentRecommendation: (data) => {
      set((state) => ({
        contentRecommendation: {
          list:
            get(data, 'data._metadata.page', 1) > 1
              ? [
                  ...state.contentRecommendation.list,
                  ...get(data, 'data.data', []),
                ]
              : get(data, 'data.data', []),
          loadMore: get(data, 'data._metadata.load_more', false),
          currentPage: get(data, 'data._metadata.page', 1),
        },
      }))
    },
  }
}
