import { useQuery } from '@tanstack/react-query'
import * as service from 'services/campaign'

export const useCampaignSection = () => {
  return useQuery({
    queryKey: ['home-campaign-section'],
    queryFn: async () => {
      const response = await service.getSectionList()
      return response?.data?.data
    },
    enabled: false,
    retry: 1,
  })
}
