import { create } from 'zustand'
import { createEventRecommendationSlice } from './Event'
import { IEventRecommendation } from './Event/interface'
import { createContentRecommendationSlice } from '../Recommendation/Content'
import { IContentRecommendation } from '../Recommendation/Content/interface'
import { createSessionRecommendationSlice } from '../Recommendation/Session'
import { ISessionRecommendation } from '../Recommendation/Session/interface'
import { createCommunityRecommendationSlice } from '../Recommendation/Community'
import { ICommunityRecommendation } from '../Recommendation/Community/interface'

export const useEventRecommendationStore =
  create<IEventRecommendation>()((...a) => ({
    ...createEventRecommendationSlice(...a),
  }))

export const useContentRecommendationStore =
  create<IContentRecommendation>()((...a) => ({
    ...createContentRecommendationSlice(...a),
  }))

export const useSessionRecommendationStore =
  create<ISessionRecommendation>()((...a) => ({
    ...createSessionRecommendationSlice(...a),
  }))

export const useCommunityRecommendationStore =
  create<ICommunityRecommendation>()((...a) => ({
    ...createCommunityRecommendationSlice(...a),
  }))
