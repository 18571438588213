import { StateCreator } from 'zustand'
import { ISessionRecommendation } from './interface'

export const createSessionRecommendationSlice: StateCreator<
  ISessionRecommendation
> = (set) => {
  return {
    sessionRecommendation: [],
    isLoadingSessionRecommendation: false,
    setSessionRecommendation(data) {
      set({
        sessionRecommendation: data,
      })
    },
  }
}
