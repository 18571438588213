import * as React from 'react'
import { styled } from '@mui/material/styles'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import CreatorCardSkeleton from './CreatorCardSkeleton'

const StyledContainer = styled('div')(({ theme }) => ({
  marginBottom: '24px',
  width: '100%',
  overflow: 'hidden',
  padding: '0px 128px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h3Desk,
  },
  color: theme.palette.text.primary,
  left: '0',
  alignSelf: 'flex-start',
  marginBottom: '24px',
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 281,
  },
}))

function CreatorsSliderSkeleton() {
  return (
    <StyledContainer>
      <StyledTypography>Kreator Populer</StyledTypography>
      <StyledStack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={1}
      >
        {Array.from(Array(6)).map((_, i) => (
          <CreatorCardSkeleton key={i} />
        ))}
      </StyledStack>
    </StyledContainer>
  )
}

export default CreatorsSliderSkeleton
