import * as React from 'react'
import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'

const StyledCard = styled('div')({
  width: '100%',
  height: 281,
  position: 'relative',
})

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '8px',
  minWidth: 163,
  minHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 281,
  },
  backgroundColor: 'lightgrey',
}))

function CreatorCardSkeleton() {
  return (
    <StyledCard>
      <StyledSkeleton variant="rectangular" />
    </StyledCard>
  )
}

export default CreatorCardSkeleton
