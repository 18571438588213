import { StateCreator } from 'zustand'
import {
  ICommunityRecommendation,
  ICommunityRecommendationStateDefault,
} from './interface'

const resetters: (() => void)[] = []
const INITIAL_DATA: ICommunityRecommendationStateDefault = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  communityRecommendation: {
    list: [],
    loadMore: false,
    currentPage: 1,
  },
}

export const createCommunityRecommendationSlice: StateCreator<
  ICommunityRecommendation
> = (set) => {
  resetters.push(() => set(INITIAL_DATA))

  return {
    ...INITIAL_DATA,
    setCommunityRecommendation: (data) => {
      set((state) => ({
        communityRecommendation: {
          list:
            data?._metadata?.page > 1
              ? [...state.communityRecommendation.list, ...data.data]
              : data.data,
          loadMore: data?._metadata?.load_more || false,
          currentPage: data._metadata?.page || 1,
        },
      }))
    },
    setError: (message: string) => {
      set({
        isError: true,
        errorMessage: message,
      })
    },
    setLoading: (isLoading: boolean) => {
      set({
        isLoading,
      })
    },
    clearError: () => {
      set({
        isError: false,
        errorMessage: '',
      })
    },
  }
}
