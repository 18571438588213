import { styled } from '@mui/material/styles'

export const StyledCommunitiesBanner = styled('img')(({ theme }) => ({
  width: '100%',
  height: '163px',
  borderRadius: '12px',
  objectFit: 'cover',
  objectPosition: 'center',
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    height: '270px',
  },
}))
