import { Skeleton, styled } from '@mui/material'
import Stack from 'components/commons/Stack'
import * as React from 'react'

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  padding: '0px 128px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px',
  },
}))

const StyledCard = styled(Skeleton)(({ theme }) => ({
  width: 140,
  height: 140,
  minWidth: 140,
  position: 'relative',
  paddingLeft: 0,
  cursor: 'pointer',
  borderRadius: 8,
  background: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    height: 211,
    width: '100%',
  },
}))

function PrivateSessionSkeleton() {
  return (
    <StyledContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        {[...Array(6)].map((_item, _index) => {
          return (
            <StyledCard
              key={`private-session-skeleton-${_index}`}
              variant="rectangular"
              animation="pulse"
            />
          )
        })}
      </Stack>
    </StyledContainer>
  )
}

export default PrivateSessionSkeleton
