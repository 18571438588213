import StateImage from 'components/commons/StateImage'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import Button from 'components/commons/Button'

import { useReferralStore } from 'stores/domains/User'
import appConfig from 'configs/app'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'

const ReferralCodeExistDrawer = () => {
  const { pathname } = useRouter()
  const {
    showReferralExistDrawer,
    setShowReferralExistDrawer,
    clearReferralCodeInfo,
  } = useReferralStore((state) => ({
    showReferralExistDrawer: state.showReferralExistDrawer,
    setShowReferralExistDrawer: state.setShowReferralExistDrawer,
    clearReferralCodeInfo: state.clearReferralCodeInfo,
  }))

  const handleCloseDrawer = () => {
    setShowReferralExistDrawer(false)
    clearReferralCodeInfo()
  }

  return (
    <ModalDrawer
      title=""
      isOpen={showReferralExistDrawer}
      onClose={handleCloseDrawer}
      closeButtonId="b-common-close-referral-exist-drawer"
    >
      <Stack
        alignItems={'center'}
        textAlign={'center'}
        spacing={3}
        paddingBottom={{ xs: 3, md: 0 }}
      >
        <StateImage
          src={`${appConfig.assetBaseUrl}/webp/state_no_respond.webp`}
        />
        <Stack spacing={1}>
          <Typography
            sx={(theme) => ({
              ...theme.typography.h3,
              color: theme.palette.text.primary,
            })}
          >
            Kamu tidak dapat menggunakan kode referral baru untuk
            akunmu
          </Typography>
          <Typography
            sx={(theme) => ({
              ...theme.typography.normalRegular,
              color: theme.palette.text.secondary,
            })}
          >
            Maksimal hanya dapat menggunakan satu kode referral untuk
            akunmu
          </Typography>
        </Stack>
        {pathname !== '/' && (
          <Button
            id="b-common-back-to-home-referral-exist"
            onClick={() => {
              redirect('/')
              handleCloseDrawer()
            }}
            sx={{
              width: '100%',
              borderRadius: '8px',
              height: '44px',
            }}
          >
            Kembali ke Beranda
          </Button>
        )}
      </Stack>
    </ModalDrawer>
  )
}

export default ReferralCodeExistDrawer
