import * as React from 'react'
import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'

interface IProps {
  secondary?: boolean
}

const Container = styled('div')<IProps>(({ secondary }) => ({
  width: '100%',
  height: secondary ? '211px' : '400px',
  position: 'relative',
}))

const StyledSkeletonImage = styled(Skeleton)<IProps>(
  ({ secondary }) => ({
    width: '100%',
    height: secondary ? '211px' : '400px',
    backgroundColor: 'lightgrey',
    '@media (min-width:900px)': {
      marginTop: '0px',
    },
  }),
)

function BannerSkeleton({ secondary = false }: IProps) {
  return (
    <Container secondary={secondary}>
      <StyledSkeletonImage
        variant="rectangular"
        secondary={secondary}
      />
    </Container>
  )
}

export default BannerSkeleton
