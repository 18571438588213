import Grid from 'components/commons/Grid'
import { Skeleton, useMediaQuery, useTheme } from '@mui/material'

const ExploreSkeleton = () => {
  const theme = useTheme()

  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('md'),
  )
  return (
    <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
      {new Array(isDesktopResolution ? 12 : 4).fill(
        <Grid item xs={6} sm={2}>
          <Skeleton
            variant="rectangular"
            height={isDesktopResolution ? '363px' : '220px'}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.tertiery,
              borderRadius: '12px',
            })}
          />
        </Grid>,
      )}
    </Grid>
  )
}

export default ExploreSkeleton
