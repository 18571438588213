import { StateCreator } from 'zustand'
import {
  IEventRecommendation,
  IEventRecommendationStateDefault,
} from './interface'

const resetters: (() => void)[] = []

const INITIAL_DATA: IEventRecommendationStateDefault = {
  eventRecommendation: {
    list: [],
    loadMore: false,
    currentPage: 1,
  },
  eventOtherData: {
    list: [],
    loadMore: false,
    currentPage: 1,
  },
  isLoading: false,
  isError: false,
  errorMessage: '',
}

export const createEventRecommendationSlice: StateCreator<
  IEventRecommendation
> = (set) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setEventRecommendation: (data) => {
      set((state) => ({
        eventRecommendation: {
          list:
            data._metadata.page > 1
              ? [...state.eventRecommendation.list, ...data.data]
              : data.data,
          loadMore: data._metadata.load_more || false,
          currentPage: data._metadata.page || 1,
        },
      }))
    },
    setError: (message: string) => {
      set({
        isError: true,
        errorMessage: message,
      })
    },
    setLoading: (isLoading: boolean) => {
      set({
        isLoading,
      })
    },
    clearError: () => {
      set({
        isError: false,
        errorMessage: '',
      })
    },
    setEventOtherData: (data) => {
      set((state) => ({
        eventOtherData: {
          list:
            data._metadata.page > 1
              ? [...state.eventOtherData.list, ...data.data]
              : data.data,
          loadMore: data._metadata.load_more || false,
          currentPage: data._metadata.page || 1,
        },
      }))
    },
  }
}
