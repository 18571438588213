import { Link, SxProps, useMediaQuery, useTheme } from '@mui/material'
import {
  StyledCardContainer,
  StyledCommunityCategory,
  StyledCommunityName,
  StyledImageContainer,
} from './styled'
import { CommunityImage } from '../CommunityImage'
import { ICommunity } from 'stores/domains/Social/Community/interface'
import isEmpty from 'helpers/is-empty'
import imageResizer from 'helpers/image-resizer'
import appConfig from 'configs/app'
import redirect from 'helpers/redirect'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import {
  useCategoryStore,
  useProfileUserStore,
} from 'stores/domains/User'
import { checkQuery } from 'helpers/browse'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'

interface ICommunityCardProps {
  community: ICommunity
  sxContainer?: SxProps
  trackerProperties?: any
}

const CommunityCard = ({
  community,
  sxContainer,
  trackerProperties,
}: ICommunityCardProps) => {
  const { pathname, query } = useRouter()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const { categories, selectedCategories } = useCategoryStore(
    (state) => ({
      categories: state.categories,
      selectedCategories: state.selectedCategories,
    }),
  )

  const trackerData = { pathname, query, community, profileData }
  const handleClickCard = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let sectionName = 'My Community'
    const isFromBrowsePage = pathname.includes('browse')
    if (isFromBrowsePage) {
      sectionName = 'browse_community'
    } else {
      sectionName =
        profileData.user_id === community.owner_id
          ? 'My Community'
          : 'Followed Community'
    }

    trackEvent.community(
      'select_community',
      trackerData,
      !isEmpty(trackerProperties)
        ? trackerProperties
        : {
            section_name: sectionName,
            selected_browse_and_search_menu: `${
              query?.status || 'all'
            }_tab`,
            list_selected_category_id: isEmpty(query.category_id)
              ? []
              : checkQuery(query.category_id),
            list_selected_category_name: isEmpty(query.category_id)
              ? []
              : categories
                  .filter((cat) =>
                    selectedCategories?.includes(cat.id),
                  )
                  .map((cat) => cat.name),
          },
    )
    redirect(
      `/k/${community.mask_id}?source=${pathname}&source_action=trigger_select_community`,
    )
  }
  return (
    <StyledCardContainer
      onClick={handleClickCard}
      sx={sxContainer}
      className="c-social-community-card"
    >
      <Box sx={{ height: '137px', width: '100%' }}>
        <StyledImageContainer
          src={
            isEmpty(community?.banner_image)
              ? imageResizer(
                  `${appConfig.assetBaseUrl}/placeholder/${
                    isDesktop
                      ? 'community-banner_desktop'
                      : 'community-mobile-banner'
                  }.png`,
                  isDesktop ? '700w' : '500w',
                )
              : community.banner_image
          }
          sx={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Box>
      <Stack alignItems="center" flex={1}>
        <CommunityImage
          sxContainer={{
            marginTop: '-60px',
          }}
          src={
            isEmpty(community?.thumbnail_image)
              ? imageResizer(
                  `${appConfig.assetBaseUrl}/placeholder/community-${
                    isDesktop ? 'desktop' : 'mobile'
                  }-avatar.png`,
                  '300w',
                )
              : community.thumbnail_image
          }
        />
      </Stack>
      <Box
        padding={'0px 16px'}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
        sx={{ cursor: 'pointer', width: '100%' }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={'4px'}
        >
          <StyledCommunityName>{community.name}</StyledCommunityName>
          {community.is_private && (
            <LockRoundedIcon
              sx={(theme) => ({
                color: theme.palette.tiptip[500],
                width: '16px',
                height: '16px',
              })}
            />
          )}
        </Stack>
        <StyledCommunityCategory>
          {community.category.name}
        </StyledCommunityCategory>
      </Box>
      <Link
        style={{
          width: '100%',
          height: '100%',
          textDecoration: 'none',
          position: 'absolute',
          zIndex: 5,
        }}
        href={`/k/${community.mask_id}?source=${pathname}&source_action=trigger_select_community`}
      />
    </StyledCardContainer>
  )
}

export default CommunityCard
