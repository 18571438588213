import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { StyledCommunitiesBanner } from './styled'
import appConfig from 'configs/app'
import redirect from 'helpers/redirect'
import imageResizer from 'helpers/image-resizer'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import { useProfileUserStore } from 'stores/domains/User'

const ExploreCommunitiesBanner = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { pathname, query } = useRouter()

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const trackerData = { pathname, query, profileData }

  const handleRedirect = () => {
    trackEvent.community('click_browse_community', trackerData)
    redirect(
      `/browse?status=community&source=${pathname}&source_action=browse_all_community`,
    )
  }

  return (
    <StyledCommunitiesBanner
      alt="explore-communities-banner"
      src={imageResizer(
        `${
          appConfig.staticBaseUrl
        }/content/banner/banner_explore_community_${
          isDesktop ? 'desktop' : 'mobile'
        }.png`,
        isDesktop ? '1200w' : '500w',
      )}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = ''
        currentTarget.style.display = 'none'
      }}
      onClick={handleRedirect}
    />
  )
}

export default ExploreCommunitiesBanner
