import { Chip, styled, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import Stack from 'components/commons/Stack'
import SectionContainer from '../SectionContainer/v2'
import Box from 'components/commons/Box'
import EventTab from './EventTab'
import ContentTab from './ContentTab'
import SessionTab from './SessionTab'
import CommunityTab from './CommunityTab'
import { TabsHome } from 'constants/common'
import { useTranslation } from 'react-i18next'

const TabButton = styled(Chip)({
  height: '32px',
  padding: '8px 16px',
})

const Wrapper = styled(Box)({
  display: 'grid',
  marginTop: '16px !important',
})

const ExploreSection = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [currentActiveTab, setCurrentActiveTab] = useState(
    TabsHome.Event,
  )

  const TABS = [
    { id: 1, label: t('productItemLabelEvent'), value: 'event' },
    {
      id: 2,
      label: t('productItemLabelDigitalContent'),
      value: 'content',
    },
    {
      id: 3,
      label: t('productItemLabelLiveSession'),
      value: 'session',
    },
    { id: 4, label: t('community'), value: 'community' },
  ]

  const clickedButtonHandler = (value) => {
    setCurrentActiveTab(value)
  }

  const DataComponent = ({ data, index }) => {
    return (
      <TabButton
        id={`b-home-best-content-type${index}`}
        key={`home-event-type${data.value}`}
        onClick={() => clickedButtonHandler(data?.value)}
        label={data.label}
        sx={{
          backgroundColor:
            currentActiveTab === data.value &&
            theme.palette.primary.main,
          border:
            currentActiveTab === data.value &&
            `1px solid ${theme.palette.tiptip[500]}`,
          color:
            currentActiveTab === data.value &&
            theme.palette.background.primary,
          ':hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.primary,
          },
        }}
      />
    )
  }

  const renderSection = () => {
    switch (currentActiveTab) {
      case 'event':
        return <EventTab />
      case 'content':
        return <ContentTab />
      case 'session':
        return <SessionTab />
      case 'community':
        return <CommunityTab />
      default:
        return null
    }
  }

  return (
    <SectionContainer
      showViewAll={false}
      sxContainer={{
        padding: isDesktop ? '0px 128px !important' : '0px 16px',
      }}
    >
      <Stack
        direction={'row'}
        gap={`8px`}
        sx={{
          width: '100%',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
        }}
      >
        {TABS.map((item, i) => {
          return <DataComponent key={i} data={item} index={i} />
        })}
      </Stack>
      <Wrapper>{renderSection()}</Wrapper>
    </SectionContainer>
  )
}

export default ExploreSection
