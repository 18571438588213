import { styled } from '@mui/material'
import {
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import Attention from 'components/commons/Attention/v2'
import { ButtonAttention } from 'components/commons/Attention/v2/styled'
import Box from 'components/commons/Box'
import GeneralProductCard from 'components/commons/Card/GeneralProductCard'
import LoadingButton from 'components/commons/LoadingButton'
import StateImage from 'components/commons/StateImage'
import imageResizer from 'helpers/image-resizer'
import { sendLog } from 'helpers/log'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { getContentRecommendation } from 'services/recommendation'
import { useStoreActions, useStoreState } from 'stores/hooks'
import EmptyStateAttention from '../EmptyStateAttention'
import ExploreSkeleton from '../ExploreSkeleton'
import { useTranslation } from 'react-i18next'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    gap: '8px',
    gridTemplateColumns: 'repeat(2, minmax(168px, 394px))',
  },
  gridTemplateColumns: 'repeat(6, minmax(100px, 300px))',
}))

const ContentTab = () => {
  const { t } = useTranslation()

  const { pathname, isReady } = useRouter()
  const DEFAULT_LIMIT = 10
  const queryClient = useQueryClient()
  const source = `?source=${pathname}&source_action=`
  const { isError, contentData } = useStoreState(
    (state) => state.browseContent,
  )

  const { setBrowseContent, setError, resetError } = useStoreActions(
    (action) => action.browseContent,
  )

  const handleClickSelectedContent = (item) => {
    const targetUrl = `/content/${item.product_id}${source}`
    redirect(targetUrl)
  }

  const loadContent = async (page: number) => {
    let params = {
      page,
      limit: DEFAULT_LIMIT,
    }

    resetError()
    try {
      const resultRecommendation = await getContentRecommendation({
        ...params,
      })
      setBrowseContent(resultRecommendation)
      return resultRecommendation
    } catch (error) {
      sendLog(error)
      setError(
        error?.response?.data?.message || 'Ups, terjadi kesalahan',
      )
    }
  }

  const fetchContents = async ({ pageParam = 1 }) => {
    const rest = await loadContent(pageParam)
    return rest?.data || []
  }

  const {
    data,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(['contents_recommendation'], fetchContents, {
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage._metadata) return
      return lastPage?._metadata.page + 1
    },
    enabled: false,
  })

  const contentsData = useMemo(() => {
    return data?.pages.map(({ data }) => data).flat() ?? []
  }, [data])

  const clearData = () => {
    queryClient.removeQueries(['contents_recommendation'])
  }

  useEffect(() => {
    if (isReady) {
      clearData()
      refetch()
    }
  }, [isReady])

  if (isFetching && !isFetchingNextPage) {
    return <ExploreSkeleton />
  }

  if (isError) {
    return (
      <Attention
        title={t('failedToDisplayPage')}
        subtitle={t('requestTimedOutSubtitle')}
        image={<StateImage type="error" />}
        actions={[
          <ButtonAttention
            id="b-home-error-event"
            key="b-home-error-event"
            onClick={() => {}}
          >
            {t('refresh')}
          </ButtonAttention>,
        ]}
      />
    )
  }

  if (contentsData.length === 0) {
    return <EmptyStateAttention status={'content'} />
  }

  return (
    <>
      <Container>
        {contentsData?.map((item, _index) => (
          <GeneralProductCard
            key={`product-${item.product_id}`}
            onClick={() => handleClickSelectedContent(item)}
            href={`/content/${item?.product_id}${source}`}
            image={imageResizer(item?.cover, '300w')}
            typeName={item.type.name}
            title={item.title}
            creatorName={item.creator.name}
            salePrice={item.sale_price}
            price={item.price}
            teaser={item.teaser_file}
            playable={!!item.teaser_file || false}
            isVerified={item.creator.verified}
            eventStatus={item?.type?.name}
            location={item?.event_summary?.location_city}
            startDate={item?.event_summary?.event_started_at}
            endDate={item?.event_summary?.event_ended_at}
            locked={item?.is_lock}
            productType={'content'}
          />
        ))}
      </Container>
      {contentData?.loadMore && (
        <LoadingButton
          buttonVariant="ghost"
          sx={{ width: '152px', height: 44, margin: 'auto', mt: 8 }}
          onClick={() => {
            fetchNextPage()
          }}
          loading={isFetchingNextPage}
        >
          {t('showAll')}
        </LoadingButton>
      )}
    </>
  )
}

export default ContentTab
