import { StateCreator } from 'zustand'
import { sendLog } from 'helpers/log'
import { getSectionDetail, getSectionList } from 'services/campaign'
import {
  ISectionCampaignDetailPayload,
  IThematicCampaign,
} from './interface'
import isEmpty from 'helpers/is-empty'
import { isGuestMode } from 'helpers/cookie'

export const createThematicCampaignStore: StateCreator<
  IThematicCampaign
> = (set, get) => ({
  // states
  sectionCampaign: [],
  isLoadingSection: false,
  sectionCampaignDetail: {
    section_id: '',
    section_title: '',
    section_type: '',
    session_list: [],
    creator_list: [],
    content_list: [],
    load_more: false,
  },

  getCampaignSectionList: () => {
    set({
      isLoadingSection: true,
    })
    getSectionList()
      .then((response) => {
        if (response?.data?.code === 'SUCCESS') {
          set({
            sectionCampaign: response.data.data,
            isLoadingSection: false,
          })
        }
      })
      .catch((error) => {
        sendLog(error)
        set({
          isLoadingSection: false,
        })
      })
  },
  setCampaignSectionList(data) {
    set({
      sectionCampaign: data,
      isLoadingSection: false,
    })
  },
  getCampaignSectionDetail: async (
    sectionId: string,
    params: ISectionCampaignDetailPayload,
  ) => {
    if (!isEmpty(sectionId)) {
      set(() => ({
        isLoadingSection: true,
      }))
      const url =
        `/campaign${
          isGuestMode() ? '/guest' : ''
        }/v1/section/${sectionId}?` +
        (params?.category_id || '') +
        (params?.type || '')
      try {
        const response = await getSectionDetail(url, {
          page: params.page,
          limit: params.limit,
        })
        set({
          sectionCampaignDetail: response.data.data,
        })
        return response
      } catch (error) {
        sendLog(error)
      } finally {
        set(() => ({
          isLoadingSection: false,
        }))
      }
    }
  },
  editFollowingCreator: (payload) => {
    const index = get().sectionCampaignDetail.creator_list.findIndex(
      (creator) => creator.username === payload.creator_username,
    )
    const tempCreatorList = [
      ...get().sectionCampaignDetail.creator_list,
    ]
    tempCreatorList[index].follower.is_following =
      payload.is_following
    tempCreatorList[index].follower.total = payload.follower_number
    set(() => ({
      sectionCampaignDetail: {
        ...get().sectionCampaignDetail,
        creator_list: [...tempCreatorList],
      },
    }))
  },
  resetSectionCampaign: () => {
    set(() => ({
      sectionCampaignDetail: {
        section_id: '',
        section_title: '',
        section_type: '',
        session_list: [],
        creator_list: [],
        content_list: [],
        load_more: false,
      },
    }))
  },
})
