import { styled } from '@mui/material/styles'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

export const StyledCardContainer = styled(Stack)(({ theme }) => ({
  gap: 16,
  padding: '8px',
  background: theme.palette.background.primary,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingBottom: '16px',
  cursor: 'pointer',
  position: 'relative',
  boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
}))

interface IImageContainerProps {
  src?: string
}

export const StyledImageContainer = styled(Box)<IImageContainerProps>(
  ({ src }) => ({
    borderRadius: '12px 12px 0px 0px',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${src}), lightgray 50% / cover no-repeat`,
    width: '100%',
    height: '137px',
  }),
)

export const StyledCommunityName = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.mediumBold,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '130px',
  }),
)

export const StyledCommunityCategory = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.smallRegular,
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: 4,
  }),
)
