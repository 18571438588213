import { styled } from '@mui/material'
import Box from 'components/commons/Box'
import imageResizer from 'helpers/image-resizer'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'
import ExploreSkeleton from '../ExploreSkeleton'
import StateImage from 'components/commons/StateImage'
import Attention from 'components/commons/Attention/v2'
import { ButtonAttention } from 'components/commons/Attention/v2/styled'
import {
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { sendLog } from 'helpers/log'
import { getRecommendationSession } from 'services/session'
import { useBrowseRecommendationSessionStore } from 'stores/domains/Browse'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { useEffect, useMemo } from 'react'
import LoadingButton from 'components/commons/LoadingButton'
import GeneralProductCard from 'components/commons/Card/GeneralProductCard'
import EmptyStateAttention from '../EmptyStateAttention'
import { useTranslation } from 'react-i18next'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    gap: '8px',
    gridTemplateColumns: 'repeat(2, minmax(100px, 300px))',
  },
  gridTemplateColumns: 'repeat(6, minmax(100px, 300px))',
}))

const SessionTab = () => {
  const { t } = useTranslation()

  const { pathname, isReady } = useRouter()

  const queryClient = useQueryClient()

  const DEFAULT_LIMIT = 10

  const source = `?source=${pathname}&source_action=`

  const { isError } = useStoreState((state) => state.browseSession)

  const { setError: setSessionError, resetError: resetSessionError } =
    useStoreActions((action) => action.browseSession)

  const { setRecommendationSession, recommendationSession } =
    useBrowseRecommendationSessionStore((state) => ({
      setRecommendationSession: state.setRecommendationSession,
      recommendationSession: state.recommendationSession,
    }))

  const handleClickSelectedContent = (item) => {
    const targetUrl = `/session/${item.id}${source}`
    redirect(targetUrl)
  }

  const loadSession = async (page: number) => {
    const params = {
      page,
      limit: DEFAULT_LIMIT,
    }

    try {
      const response = await getRecommendationSession(params)
      setRecommendationSession(response)
      return response
    } catch (error) {
      sendLog(error)
      setSessionError(
        error?.response?.data?.message || 'Ups, terjadi kesalahan',
      )
    }
  }

  const fetchSessions = async ({ pageParam = 1 }) => {
    resetSessionError()
    const rest = await loadSession(pageParam)
    return rest?.data || []
  }

  const {
    data,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(['sessions_recommendation'], fetchSessions, {
    getNextPageParam: (lastPage) => {
      return lastPage?._metadata?.page + 1 || 1
    },
    enabled: false,
  })

  const sessionsData = useMemo(() => {
    return data?.pages.map(({ data }) => data).flat() ?? []
  }, [data])

  const clearData = () => {
    queryClient.removeQueries(['sessions_recommendation'])
  }
  useEffect(() => {
    if (isReady) {
      clearData()
      refetch()
    }
  }, [isReady])

  if (isError) {
    return (
      <Attention
        title={t('failedToDisplayPage')}
        subtitle={t('requestTimedOutSubtitle')}
        image={<StateImage type="error" />}
        actions={[
          <ButtonAttention
            id="b-home-error-event"
            key="b-home-error-event"
            onClick={() => {}}
          >
            {t('refresh')}
          </ButtonAttention>,
        ]}
      />
    )
  }

  if (isFetching && !isFetchingNextPage) {
    return <ExploreSkeleton />
  }

  if (sessionsData.length === 0) {
    return <EmptyStateAttention status={'session'} />
  }

  return (
    <>
      <Container>
        {sessionsData?.map((item, _i) => (
          <GeneralProductCard
            key={`product-${item.id}`}
            onClick={() => {
              handleClickSelectedContent(item)
            }}
            href={`/session/${item.id}${source}`}
            image={imageResizer(item.background_img, '300w')}
            title={item.name}
            creatorName={item.creator_name}
            isVerified={item.creator.verified}
            productType={'session'}
            status={item.status}
            locked={false}
            sessionType={item.session_type}
          />
        ))}
      </Container>
      {recommendationSession.loadMore && (
        <LoadingButton
          buttonVariant="ghost"
          sx={{ width: '152px', height: 44, margin: 'auto', mt: 8 }}
          onClick={() => {
            fetchNextPage()
          }}
          loading={isFetchingNextPage}
        >
          {t('showAll')}
        </LoadingButton>
      )}
    </>
  )
}

export default SessionTab
