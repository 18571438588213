import Attention from 'components/commons/Attention/v2'
import StateImage from 'components/commons/StateImage'
import { productType } from 'constants/common'
import { useTranslation } from 'react-i18next'

type IEmptyStateAttention = {
  status?: productType
}
function EmptyStateAttention({ status }: IEmptyStateAttention) {
  const { t } = useTranslation()

  const renderSentence = (status) => {
    let title = ''
    switch (status) {
      case 'community':
        title = t('community')
        break
      case 'content':
        title = t('headerSourceDigitalContent')
        break
      case 'session':
        title = t('creatorProfileLiveSessionTabLabel')
        break
      case 'event':
        title = t('eventSectionTitle')
        break
      default:
        title = t('headerSourceDigitalContent')
        break
    }
    return title
  }
  const renderTitleAttention = () => {
    return {
      title: `Belum ada ${renderSentence(status)} saat ini`,
      subtitle: t('emptyExploreSessionDesc'),
      renderButton: false,
    }
  }
  return (
    <Attention
      image={<StateImage type="empty-search" />}
      title={renderTitleAttention().title}
      subtitle={renderTitleAttention().subtitle}
    />
  )
}

export default EmptyStateAttention
