import { styled } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import Attention from 'components/commons/Attention/v2'
import { ButtonAttention } from 'components/commons/Attention/v2/styled'
import Box from 'components/commons/Box'
import GeneralProductCard from 'components/commons/Card/GeneralProductCard'
import LoadingButton from 'components/commons/LoadingButton'
import StateImage from 'components/commons/StateImage'
import { getErrorMessage } from 'helpers/error'
import imageResizer from 'helpers/image-resizer'
import { sendLog } from 'helpers/log'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { getEventRecommendation } from 'services/event'
import { useEventRecommendationStore } from 'stores/domains/Recommendation'
import EmptyStateAttention from '../EmptyStateAttention'
import ExploreSkeleton from '../ExploreSkeleton'
import { useTranslation } from 'react-i18next'
import { useLocationStore } from 'stores/domains/Social'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    gap: '8px',
    gridTemplateColumns: 'repeat(2, minmax(168px, 394px))',
  },
  gridTemplateColumns: 'repeat(6, minmax(100px, 300px))',
}))

const EventTab = () => {
  const { t } = useTranslation()

  const { pathname, isReady } = useRouter()
  const DEFAULT_LIMIT = 10

  const source = `?source=${pathname}&source_action=`

  const { selectedFilterProvince } = useLocationStore((state) => ({
    selectedFilterProvince: state.selectedFilterProvince,
  }))

  const {
    eventRecommendation,
    isError,
    setEventRecommendation,
    clearError,
    setError,
  } = useEventRecommendationStore((state) => ({
    setEventRecommendation: state.setEventRecommendation,
    eventRecommendation: state.eventRecommendation,
    isError: state.isError,
    clearError: state.clearError,
    setError: state.setError,
  }))

  const handleClickSelectedContent = (item) => {
    const targetUrl = `/event/${item.product_id}${source}`
    redirect(targetUrl)
  }

  const loadEvent = async (page: number) => {
    let params = {
      page,
      limit: DEFAULT_LIMIT,
      event_types: '',
      category_id: '',
      province: selectedFilterProvince?.id,
    }

    clearError()
    try {
      const res = await getEventRecommendation(params)
      setEventRecommendation(res.data)
      return res
    } catch (error) {
      sendLog(error)
      setError(getErrorMessage(error))
    }
  }

  const fetchEvents = async ({ pageParam = 1 }) => {
    const rest = await loadEvent(pageParam)
    return rest?.data || []
  }

  const {
    data,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['events_recommendation_infinite'],
    fetchEvents,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage._metadata) return
        return lastPage?._metadata.page + 1
      },
      enabled: false,
    },
  )

  const events = useMemo(() => {
    return data?.pages.map(({ data }) => data).flat() ?? []
  }, [data])

  useEffect(() => {
    if (isReady) {
      refetch()
    }
  }, [isReady, selectedFilterProvince])

  if (isFetching && !isFetchingNextPage) {
    return <ExploreSkeleton />
  }

  if (isError) {
    return (
      <Attention
        title={t('failedToDisplayPage')}
        subtitle={t('requestTimedOutSubtitle')}
        image={<StateImage type="error" />}
        actions={[
          <ButtonAttention
            id="b-home-error-event"
            key="b-home-error-event"
            onClick={() => {}}
          >
            {t('refresh')}
          </ButtonAttention>,
        ]}
      />
    )
  }

  if (events.length === 0) {
    return <EmptyStateAttention status={'event'} />
  }

  return (
    <>
      <Container>
        {events &&
          events?.length > 0 &&
          events?.map((item, _i) => (
            <GeneralProductCard
              key={`product-${item.product_id}`}
              onClick={() => handleClickSelectedContent(item)}
              href={`/event/${item.product_id}${source}`}
              image={imageResizer(item.cover, '300w')}
              typeName={item.type.name}
              title={item.title}
              creatorName={item.creator.name}
              salePrice={item.sale_price}
              price={item.price}
              teaser={item.teaser_file}
              playable={!!item.teaser_file || false}
              isVerified={item.creator.verified}
              eventStatus={item.event_summary?.status}
              location={item.event_summary?.location_city}
              startDate={item.event_summary?.event_started_at}
              endDate={item.event_summary?.event_ended_at}
              locked={item?.is_lock}
              eventTimezone={item?.event_summary?.event_timezone}
              productType={'event'}
            />
          ))}
      </Container>
      {eventRecommendation?.loadMore && (
        <LoadingButton
          buttonVariant="ghost"
          sx={{ width: '152px', height: 44, margin: 'auto', mt: 8 }}
          onClick={() => {
            fetchNextPage()
          }}
          loading={isFetchingNextPage}
        >
          {t('showAll')}
        </LoadingButton>
      )}
    </>
  )
}

export default EventTab
